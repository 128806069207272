define("ember-rollbar-client/services/rollbar", ["exports", "@ember/application", "@ember/object", "@ember/service", "lodash.merge", "rollbar"], function (_exports, _application, _object, _service, _lodash, _rollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    enabled: (0, _object.computed)({
      get() {
        return this.get('config.enabled');
      },

      set(key, value) {
        this.get('notifier').configure({
          enabled: value
        });
        return value;
      }

    }),
    currentUser: (0, _object.computed)({
      set(key, value) {
        this.get('notifier').configure({
          payload: {
            person: value
          }
        });
        return value;
      }

    }),
    notifier: (0, _object.computed)(function () {
      return this.rollbarClient();
    }).readOnly(),
    config: (0, _object.computed)(function () {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment').emberRollbarClient;
    }).readOnly(),

    rollbarClient() {
      var customConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var config = (0, _lodash.default)({}, this.get('config'), customConfig);
      return new _rollbar.default(config);
    },

    // Notifications
    critical() {
      var _this$get;

      return (_this$get = this.get('notifier')).critical.apply(_this$get, arguments);
    },

    error() {
      var _this$get2;

      return (_this$get2 = this.get('notifier')).error.apply(_this$get2, arguments);
    },

    warning() {
      var _this$get3;

      return (_this$get3 = this.get('notifier')).warning.apply(_this$get3, arguments);
    },

    info() {
      var _this$get4;

      return (_this$get4 = this.get('notifier')).info.apply(_this$get4, arguments);
    },

    debug() {
      var _this$get5;

      return (_this$get5 = this.get('notifier')).debug.apply(_this$get5, arguments);
    }

  });

  _exports.default = _default;
});