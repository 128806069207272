define("ember-scrollable/util/timeout", ["exports", "rsvp", "@ember/runloop"], function (_exports, _rsvp, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeout = timeout;

  function timeout(ms) {
    var promise = new _rsvp.Promise(function (r) {
      (0, _runloop.later)(r, ms);
    });
    return promise;
  }
});