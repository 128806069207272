define("@embroider/macros/runtime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.config = config;
  _exports.each = each;
  _exports.getGlobalConfig = getGlobalConfig;
  _exports.isTesting = isTesting;
  _exports.macroCondition = macroCondition;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /*
    These are the runtime implementations for the javascript macros that have
    runtime implementations.
  
    Not every macro has a runtime implementation, some only make sense in the
    build and always run there.
  
    Even when we have runtime implementations, we are still careful to emit static
    errors during the build wherever possible, and runtime errors when necessary,
    so that you're not surprised when you switch from runtime-mode to compile-time
    mode.
  */

  /*
    CAUTION: in classic builds, this file gets shared by all present copies of
    @embroider/macros. If you want to change its public API, you need to rename it
    and update `pathToRuntime` in ../babel/state.ts to point at it, so that your
    babel plugin and runtime will match.
  */
  function each(array) {
    if (!Array.isArray(array)) {
      throw new Error("the argument to the each() macro must be an array");
    }

    return array;
  }

  function macroCondition(predicate) {
    return predicate;
  } // This is here as a compile target for `getConfig` and `getOwnConfig` when
  // we're in runtime mode. This is not public API to call from your own code.


  function config(packageRoot) {
    return runtimeConfig.packages[packageRoot];
  }

  function getGlobalConfig() {
    return runtimeConfig.global;
  }

  function isTesting() {
    var g = runtimeConfig.global;
    var e = g && g['@embroider/macros'];
    return Boolean(e && e.isTesting);
  }

  var runtimeConfig = initializeRuntimeMacrosConfig(); // this exists to be targeted by our babel plugin

  function initializeRuntimeMacrosConfig() {
    return {
      "packages": {
        "/home/circleci/project/onsen/ember_app/node_modules/ember-get-config": {
          "config": {
            "modulePrefix": "onsen",
            "environment": "production",
            "rootURL": "/",
            "locationType": "auto",
            "contentSecurityPolicyHeader": "Disabled-Content-Security-Policy",
            "contentSecurityPolicy": {
              "connect-src": "*",
              "style-src": "'self' 'unsafe-inline' 'unsave-eval'"
            },
            "codeVersion": "dce7639",
            "gameVideoTour": {
              "enabled": true
            },
            "firebase": {
              "enabled": true,
              "config": {
                "apiKey": "AIzaSyBTz8FBSQORclDhBV_DaU7-jf2ro76mbLE",
                "authDomain": "glory-league-a79d0.firebaseapp.com",
                "databaseURL": "https://glory-league-a79d0.firebaseio.com",
                "projectId": "glory-league-a79d0",
                "storageBucket": "glory-league-a79d0.appspot.com",
                "messagingSenderId": "278247343982",
                "appId": "1:278247343982:web:49f26a90bb7295a789637f",
                "measurementId": "G-Z2BGXBPJ30"
              }
            },
            "userActivity": {
              "checkFrequency": 10,
              "durationBeforeInactive": 120
            },
            "SCHICK_ENABLED": false,
            "SCHICK_VIDEO_ENABLED": false,
            "analytics": {
              "debug": false
            },
            "videoAnalytics": {
              "trackingInterval": 20,
              "debug": false
            },
            "fingerprint": {
              "enabled": true,
              "prepend": null,
              "extensions": ["js", "css", "png", "jpg", "gif", "svg", "woff", "eot", "ttf", "ico", "map"]
            },
            "minifyJS": {
              "enabled": true
            },
            "minifyCSS": {
              "enabled": true
            },
            "EmberENV": {
              "environment": "production",
              "shouldBackgroundReloadRecord": true,
              "EXTEND_PROTOTYPES": {
                "Array": true,
                "Function": false,
                "String": true,
                "Date": false
              },
              "FEATURES": {},
              "_APPLICATION_TEMPLATE_WRAPPER": false,
              "_DEFAULT_ASYNC_OBSERVERS": true,
              "_JQUERY_INTEGRATION": false,
              "_TEMPLATE_ONLY_GLIMMER_COMPONENTS": true
            },
            "APP": {
              "API_NAMESPACE": "onsen/v1",
              "SUPPORT_EMAIL": "support@gloryleague.com",
              "DISTILLER_DOMAIN": "https://d32xnm58r0p7y1.cloudfront.net",
              "WWW_HOST_URL": "https://app.gloryleague.basketball",
              "API_HOST_URL": "https://api.gloryleague.com",
              "HOST_URL": "https://my.gloryleague.basketball",
              "PUBLIC_LEAGUES_DOMAIN": ".leagues.gloryleague.basketball",
              "sports": ["Basketball", "Miniball", "Futsal"],
              "sportConfig": {
                "basketball": {
                  "gameVideo": {
                    "playerTeamFilter": {
                      "defaults": {
                        "mode": "selected",
                        "selectPlayer": true
                      },
                      "spectator": {
                        "mode": "all",
                        "selectPlayer": false
                      },
                      "referee": {
                        "mode": "all",
                        "selectPlayer": false
                      }
                    },
                    "versusTeamFilter": {
                      "defaults": {
                        "mode": "none"
                      },
                      "referee": {
                        "mode": "all"
                      }
                    },
                    "eventTypeFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectedIds": []
                      },
                      "referee": {
                        "mode": "selected",
                        "selectedIds": ["foul"]
                      }
                    },
                    "filterTemplateIds": ["1-point", "2-point", "3-point", "assist", "block", "steal", "offensive-rebound", "defensive-rebound", "turnover", "miss", "foul", "reaction"],
                    "tagReactionTemplateIds": ["funny", "wow", "sad", "cool", "good-job", "clip"],
                    "tagStatTemplateIds": ["1-point", "2-point", "3-point", "assist", "block", "steal", "offensive-rebound", "defensive-rebound", "turnover", "1-point-miss", "2-point-miss", "3-point-miss"]
                  }
                },
                "miniball": {
                  "gameVideo": {
                    "playerTeamFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectPlayer": false
                      }
                    },
                    "versusTeamFilter": {
                      "defaults": {
                        "mode": "none"
                      },
                      "referee": {
                        "mode": "all"
                      }
                    },
                    "eventTypeFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectedIds": []
                      },
                      "referee": {
                        "mode": "selected",
                        "selectedIds": ["foul"]
                      }
                    },
                    "filterTemplateIds": ["1-point", "2-point", "3-point", "assist", "block", "steal", "offensive-rebound", "defensive-rebound", "turnover", "miss", "foul", "reaction"],
                    "tagReactionTemplateIds": ["funny", "wow", "sad", "cool", "good-job", "clip"],
                    "tagStatTemplateIds": ["1-point", "2-point", "3-point", "assist", "block", "steal", "offensive-rebound", "defensive-rebound", "turnover", "1-point-miss", "2-point-miss", "3-point-miss"]
                  }
                },
                "futsal": {
                  "gameVideo": {
                    "playerTeamFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectPlayer": false
                      }
                    },
                    "versusTeamFilter": {
                      "defaults": {
                        "mode": "none"
                      },
                      "referee": {
                        "mode": "all"
                      }
                    },
                    "eventTypeFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectedIds": []
                      },
                      "referee": {
                        "mode": "selected",
                        "selectedIds": ["foul"]
                      }
                    },
                    "filterTemplateIds": ["made-futsal", "miss", "assist", "save"],
                    "tagStatTemplateIds": ["1-point", "1-point-miss", "save", "assist"]
                  }
                }
              },
              "name": "onsen",
              "version": "0.0.0+dce7639b"
            },
            "emberRollbarClient": {
              "enabled": true,
              "accessToken": "121f2421724f4b0189c669cfb44c3cfb",
              "verbose": true,
              "captureUncaught": true,
              "captureUnhandledRejections": true,
              "payload": {
                "environment": "production",
                "client": {
                  "javascript": {
                    "source_map_enabled": true,
                    "code_version": "dce7639",
                    "guess_uncaught_frames": true
                  }
                }
              }
            },
            "ember-simple-auth": {
              "urlKey": "auth",
              "authenticator": "authenticator:devise"
            },
            "@ember-decorators/argument": {
              "ignoreComponentsWithoutValidations": true
            },
            "exportApplicationGlobal": false,
            "ember-modal-dialog": {
              "hasEmberTether": "2.0.1"
            }
          },
          "testConfig": {
            "modulePrefix": "onsen",
            "environment": "test",
            "rootURL": "/",
            "locationType": "auto",
            "contentSecurityPolicyHeader": "Disabled-Content-Security-Policy",
            "contentSecurityPolicy": {
              "connect-src": "*",
              "style-src": "'self' 'unsafe-inline' 'unsave-eval'"
            },
            "codeVersion": "dce7639",
            "gameVideoTour": {
              "enabled": true
            },
            "firebase": {
              "enabled": false
            },
            "userActivity": {
              "checkFrequency": 10,
              "durationBeforeInactive": 120
            },
            "SCHICK_ENABLED": false,
            "SCHICK_VIDEO_ENABLED": false,
            "analytics": {
              "debug": false
            },
            "videoAnalytics": {
              "trackingInterval": 20,
              "debug": false
            },
            "fingerprint": {
              "enabled": false,
              "prepend": null,
              "extensions": ["js", "css", "png", "jpg", "gif", "svg", "woff", "eot", "ttf", "ico", "map"]
            },
            "minifyJS": {
              "enabled": false
            },
            "minifyCSS": {
              "enabled": false
            },
            "EmberENV": {
              "environment": "test",
              "shouldBackgroundReloadRecord": true,
              "EXTEND_PROTOTYPES": {
                "Array": true,
                "Function": false,
                "String": true,
                "Date": false
              },
              "FEATURES": {},
              "_APPLICATION_TEMPLATE_WRAPPER": false,
              "_DEFAULT_ASYNC_OBSERVERS": true,
              "_JQUERY_INTEGRATION": false,
              "_TEMPLATE_ONLY_GLIMMER_COMPONENTS": true
            },
            "APP": {
              "API_NAMESPACE": "onsen/v1",
              "SUPPORT_EMAIL": "support@gloryleague.com",
              "DISTILLER_DOMAIN": "https://d32xnm58r0p7y1.cloudfront.net",
              "WWW_HOST_URL": "http://localhost:3001",
              "API_HOST_URL": "http://api.localhost:3001",
              "HOST_URL": "http://localhost:4201",
              "PUBLIC_LEAGUES_DOMAIN": ".leagues.localhost:4201",
              "sports": ["Basketball", "Miniball", "Futsal"],
              "sportConfig": {
                "basketball": {
                  "gameVideo": {
                    "playerTeamFilter": {
                      "defaults": {
                        "mode": "selected",
                        "selectPlayer": true
                      },
                      "spectator": {
                        "mode": "all",
                        "selectPlayer": false
                      },
                      "referee": {
                        "mode": "all",
                        "selectPlayer": false
                      }
                    },
                    "versusTeamFilter": {
                      "defaults": {
                        "mode": "none"
                      },
                      "referee": {
                        "mode": "all"
                      }
                    },
                    "eventTypeFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectedIds": []
                      },
                      "referee": {
                        "mode": "selected",
                        "selectedIds": ["foul"]
                      }
                    },
                    "filterTemplateIds": ["1-point", "2-point", "3-point", "assist", "block", "steal", "offensive-rebound", "defensive-rebound", "turnover", "miss", "foul", "reaction"],
                    "tagReactionTemplateIds": ["funny", "wow", "sad", "cool", "good-job", "clip"],
                    "tagStatTemplateIds": ["1-point", "2-point", "3-point", "assist", "block", "steal", "offensive-rebound", "defensive-rebound", "turnover", "1-point-miss", "2-point-miss", "3-point-miss"]
                  }
                },
                "miniball": {
                  "gameVideo": {
                    "playerTeamFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectPlayer": false
                      }
                    },
                    "versusTeamFilter": {
                      "defaults": {
                        "mode": "none"
                      },
                      "referee": {
                        "mode": "all"
                      }
                    },
                    "eventTypeFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectedIds": []
                      },
                      "referee": {
                        "mode": "selected",
                        "selectedIds": ["foul"]
                      }
                    },
                    "filterTemplateIds": ["1-point", "2-point", "3-point", "assist", "block", "steal", "offensive-rebound", "defensive-rebound", "turnover", "miss", "foul", "reaction"],
                    "tagReactionTemplateIds": ["funny", "wow", "sad", "cool", "good-job", "clip"],
                    "tagStatTemplateIds": ["1-point", "2-point", "3-point", "assist", "block", "steal", "offensive-rebound", "defensive-rebound", "turnover", "1-point-miss", "2-point-miss", "3-point-miss"]
                  }
                },
                "futsal": {
                  "gameVideo": {
                    "playerTeamFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectPlayer": false
                      }
                    },
                    "versusTeamFilter": {
                      "defaults": {
                        "mode": "none"
                      },
                      "referee": {
                        "mode": "all"
                      }
                    },
                    "eventTypeFilter": {
                      "defaults": {
                        "mode": "all",
                        "selectedIds": []
                      },
                      "referee": {
                        "mode": "selected",
                        "selectedIds": ["foul"]
                      }
                    },
                    "filterTemplateIds": ["made-futsal", "miss", "assist", "save"],
                    "tagStatTemplateIds": ["1-point", "1-point-miss", "save", "assist"]
                  }
                }
              },
              "name": "onsen",
              "version": "0.0.0+dce7639b"
            },
            "emberRollbarClient": {
              "enabled": false,
              "accessToken": "121f2421724f4b0189c669cfb44c3cfb",
              "verbose": true,
              "captureUncaught": false,
              "captureUnhandledRejections": false,
              "payload": {
                "environment": "test",
                "client": {
                  "javascript": {
                    "source_map_enabled": true,
                    "code_version": "dce7639",
                    "guess_uncaught_frames": true
                  }
                }
              }
            },
            "ember-simple-auth": {
              "urlKey": "auth",
              "authenticator": "authenticator:devise"
            },
            "@ember-decorators/argument": {
              "ignoreComponentsWithoutValidations": true
            },
            "exportApplicationGlobal": true,
            "ember-modal-dialog": {
              "hasEmberTether": "2.0.1"
            }
          }
        }
      },
      "global": {
        "@embroider/macros": {
          "isTesting": false
        }
      }
    };
  }

  function updaterMethods() {
    return {
      config,
      getGlobalConfig,

      setConfig(packageRoot, value) {
        runtimeConfig.packages[packageRoot] = value;
      },

      setGlobalConfig(key, value) {
        runtimeConfig.global[key] = value;
      }

    };
  } // this is how runtime config can get injected at boot. I'm not sure yet if this
  // should be public API, but we certainly need it internally to set things like
  // the global fastboot.isRunning.
  //
  // consumers of this API push a function onto
  // window._embroider_macros_runtime_config. The function is given four methods
  // which allow it to read and write the per-package and global configs. The
  // reason for allowing both read & write is that merging strategies are up to
  // each consumers -- read first, then merge, then write.
  //
  // For an example user of this API, see where we generate
  // embroider_macros_fastboot_init.js' in @embroider/core.


  var updaters = typeof window !== 'undefined' ? window._embroider_macros_runtime_config : undefined;

  if (updaters) {
    var methods = updaterMethods();

    var _iterator = _createForOfIteratorHelper(updaters),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var updater = _step.value;
        updater(methods);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
});