define("ember-scrollable/templates/components/ember-scrollbar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jCplmmN/",
    "block": "[[[11,0],[16,0,[29,[\"drag-handle \",[52,[30,0,[\"showHandle\"]],\"visible\"]]]],[16,5,[30,0,[\"handleStyles\"]]],[4,[38,1],[\"mousedown\",[28,[37,2],[[30,0],\"startDrag\"],null]],null],[4,[38,1],[\"mousemove\",[28,[37,2],[[30,0],\"startDrag\"],null]],null],[12],[13],[1,\"\\n\"]],[],false,[\"if\",\"on\",\"action\"]]",
    "moduleName": "ember-scrollable/templates/components/ember-scrollbar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});