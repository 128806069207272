define("ember-scrollable/templates/components/ember-scrollable", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eMd9h6Mh",
    "block": "[[[41,[30,0,[\"horizontal\"]],[[[1,\"  \"],[8,[39,1],[[4,[38,3],[[28,[37,2],[[30,0],\"recalculate\"],null]],null]],[[\"@handleOffset\",\"@handleSize\",\"@horizontal\",\"@showHandle\",\"@isDragging\",\"@onJumpTo\",\"@onDrag\",\"@onDragStart\",\"@onDragEnd\"],[[30,0,[\"horizontalHandleOffset\"]],[30,0,[\"horizontalHandleSize\"]],true,[30,0,[\"showHandle\"]],[30,0,[\"isHorizontalDragging\"]],[28,[37,2],[[30,0],\"horizontalJumpTo\"],null],[28,[37,2],[[30,0],\"horizontalDrag\"],null],[28,[37,2],[[30,0],\"horizontalDragBoundary\",true],null],[28,[37,2],[[30,0],\"horizontalDragBoundary\",false],null]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"vertical\"]],[[[1,\"  \"],[8,[39,1],[[4,[38,3],[[28,[37,2],[[30,0],\"recalculate\"],null]],null]],[[\"@handleOffset\",\"@handleSize\",\"@horizontal\",\"@showHandle\",\"@isDragging\",\"@onJumpTo\",\"@onDrag\",\"@onDragStart\",\"@onDragEnd\"],[[30,0,[\"verticalHandleOffset\"]],[30,0,[\"verticalHandleSize\"]],false,[30,0,[\"showHandle\"]],[30,0,[\"isVerticalDragging\"]],[28,[37,2],[[30,0],\"verticalJumpTo\"],null],[28,[37,2],[[30,0],\"verticalDrag\"],null],[28,[37,2],[[30,0],\"verticalBoundaryEvent\",true],null],[28,[37,2],[[30,0],\"verticalBoundaryEvent\",false],null]]],null],[1,\"\\n\"]],[]],null],[8,[39,4],null,[[\"@height\",\"@width\",\"@scrollToX\",\"@scrollToY\",\"@onScroll\"],[[30,0,[\"scrollContentHeight\"]],[30,0,[\"scrollContentWidth\"]],[30,0,[\"scrollToX\"]],[30,0,[\"scrollToY\"]],[28,[37,2],[[30,0],\"scrolled\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"tse-content scrollable-content\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,6],null,[[\"recalculate\",\"update\",\"scrollTop\"],[[28,[37,2],[[30,0],\"recalculate\"],null],[28,[37,2],[[30,0],\"update\"],null],[28,[37,2],[[30,0],\"scrollTop\"],null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"ember-scrollbar\",\"action\",\"did-resize\",\"scroll-content-element\",\"yield\",\"hash\"]]",
    "moduleName": "ember-scrollable/templates/components/ember-scrollable.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});