define("ember-resolver/resolvers/classic/container-debug-adapter", ["exports", "@ember/array", "@ember/debug/container-debug-adapter", "ember-resolver/resolvers/classic/index", "@ember/application"], function (_exports, _array, _containerDebugAdapter, _index, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getPod(type, key, prefix) {
    var match = key.match(new RegExp('^/?' + prefix + '/(.+)/' + type + '$'));

    if (match !== null) {
      return match[1];
    }
  }
  /*
   * This module defines a subclass of Ember.ContainerDebugAdapter that adds
   * support for resolving from modules.
   *
   */


  var _default = _containerDebugAdapter.default.extend({
    _moduleRegistry: null,

    init() {
      this._super.apply(this, arguments);

      this.namespace = (0, _application.getOwner)(this).lookup('application:main');

      if (!this._moduleRegistry) {
        this._moduleRegistry = new _index.ModuleRegistry();
      }
    },

    /**
        The container of the application being debugged.
        This property will be injected
        on creation.
         @property container
        @default null
        */

    /**
        The resolver instance of the application
        being debugged. This property will be injected
        on creation.
         @property resolver
        @default null
        */

    /**
        Returns true if it is possible to catalog a list of available
        classes in the resolver for a given type.
         @method canCatalogEntriesByType
        @param {string} type The type. e.g. "model", "controller", "route"
        @return {boolean} whether a list is available for this type.
        */
    canCatalogEntriesByType(type) {
      if (type === 'model') {
        return true;
      }

      return this._super.apply(this, arguments);
    },

    /**
        Returns the available classes a given type.
         @method catalogEntriesByType
        @param {string} type The type. e.g. "model", "controller", "route"
        @return {Array} An array of classes.
        */
    catalogEntriesByType(type) {
      var moduleNames = this._moduleRegistry.moduleNames();

      var types = (0, _array.A)();
      var prefix = this.namespace.modulePrefix;

      for (var i = 0, l = moduleNames.length; i < l; i++) {
        var key = moduleNames[i];

        if (key.indexOf(type) !== -1) {
          // Check if it's a pod module
          var name = getPod(type, key, this.namespace.podModulePrefix || prefix);

          if (!name) {
            // Not pod
            name = key.split(type + 's/').pop(); // Support for different prefix (such as ember-cli addons).
            // Uncomment the code below when
            // https://github.com/ember-cli/ember-resolver/pull/80 is merged.
            //let match = key.match('^/?(.+)/' + type);
            //if (match && match[1] !== prefix) {
            // Different prefix such as an addon
            //name = match[1] + '@' + name;
            //}
          }

          types.addObject(name);
        }
      }

      return types;
    }

  });

  _exports.default = _default;
});