define("ember-concurrency/-private/external/scheduler/policies/execution-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeCancelState = _exports.TYPE_STARTED = _exports.TYPE_QUEUED = _exports.TYPE_CANCELLED = _exports.STARTED = _exports.QUEUED = void 0;
  var TYPE_CANCELLED = 'CANCELLED';
  _exports.TYPE_CANCELLED = TYPE_CANCELLED;
  var TYPE_STARTED = 'STARTED';
  _exports.TYPE_STARTED = TYPE_STARTED;
  var TYPE_QUEUED = 'QUEUED';
  _exports.TYPE_QUEUED = TYPE_QUEUED;
  var STARTED = {
    type: TYPE_STARTED
  };
  _exports.STARTED = STARTED;
  var QUEUED = {
    type: TYPE_QUEUED
  };
  _exports.QUEUED = QUEUED;

  var makeCancelState = function makeCancelState(reason) {
    return {
      type: TYPE_CANCELLED,
      reason
    };
  };

  _exports.makeCancelState = makeCancelState;
});