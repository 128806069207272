define("ember-scrollable/components/ember-scrollbar", ["exports", "@ember/object", "@ember/utils", "@ember/runloop", "@ember/component", "ember-scrollable/templates/components/ember-scrollbar", "ember-scrollable/util/css", "ember-scrollable/components/ember-scrollable", "@ember/string"], function (_exports, _object, _utils, _runloop, _component, _emberScrollbar, _css, _emberScrollable, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var handleSelector = '.drag-handle';
  /**
   * Handles displaying and moving the handle within the confines of it's template.
   * Has callbacks for intending to dragging and jump to particular positions.
   *
   * @class EmberScrollbar
   * @extends Ember.Component
   */

  var _default = _component.default.extend({
    layout: _emberScrollbar.default,
    classNameBindings: [':tse-scrollbar', 'horizontal:horizontal:vertical'],

    onDrag() {},

    onJumpTo() {},

    onDragStart() {},

    onDragEnd() {},

    horizontal: false,
    isDragging: false,
    showHandle: false,
    handleSize: null,
    handleOffset: 0,
    offsetAttr: (0, _object.computed)('horizontal', function () {
      return this.get('horizontal') ? 'left' : 'top';
    }),
    jumpScrollOffsetAttr: (0, _object.computed)('horizontal', function () {
      return this.get('horizontal') ? 'offsetX' : 'offsetY';
    }),
    eventOffsetAttr: (0, _object.computed)('horizontal', function () {
      return this.get('horizontal') ? 'pageX' : 'pageY';
    }),
    sizeAttr: (0, _object.computed)('horizontal', function () {
      return this.get('horizontal') ? 'width' : 'height';
    }),
    handleStylesJSON: (0, _object.computed)('handleOffset', 'handleSize', 'horizontal', function () {
      var _this$getProperties = this.getProperties('handleOffset', 'handleSize'),
          handleOffset = _this$getProperties.handleOffset,
          handleSize = _this$getProperties.handleSize;

      if (this.get('horizontal')) {
        return {
          left: handleOffset + 'px',
          width: handleSize + 'px'
        };
      } else {
        return {
          top: handleOffset + 'px',
          height: handleSize + 'px'
        };
      }
    }),
    handleStyles: (0, _object.computed)('handleStylesJSON.{top,left,width,height}', function () {
      return (0, _css.styleify)(this.get('handleStylesJSON'));
    }),

    mouseDown(e) {
      this.jumpScroll(e);
    },

    startDrag(e) {
      // Preventing the event's default action stops text being
      // selectable during the drag.
      e.preventDefault();
      e.stopPropagation();

      var dragOffset = this._startDrag(e);

      this.set('dragOffset', dragOffset);
      this.get('onDragStart')(e);
    },

    mouseUp() {
      this.endDrag();
    },

    init() {
      this._super.apply(this, arguments);

      this.onWindowMouseMove = this.onWindowMouseMove.bind(this);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      window.addEventListener("mousemove", this.onWindowMouseMove);
    },

    willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener("mousemove", this.onWindowMouseMove);
    },

    onWindowMouseMove(e) {
      (0, _runloop.throttle)(this, this.updateMouseOffset, e, _emberScrollable.THROTTLE_TIME_LESS_THAN_60_FPS_IN_MS);
    },

    endDrag() {
      this.get('onDragEnd')();
    },

    /**
     * Callback for the mouse move event. Update the mouse offsets given the new mouse position.
     *
     * @method updateMouseOffset
     * @param e
     * @private
     */
    updateMouseOffset(e) {
      var pageX = e.pageX,
          pageY = e.pageY;
      var mouseOffset = this.get('horizontal') ? pageX : pageY;

      if (this.get('isDragging') && (0, _utils.isPresent)(mouseOffset)) {
        this._drag(mouseOffset, this.get('dragOffset'));
      }
    },

    /**
     * Handles when user clicks on scrollbar, but not on the actual handle, and the scroll should
     * jump to the selected position.
     *
     * @method jumpScroll
     * @param e
     */
    jumpScroll(e) {
      // If the drag handle element was pressed, don't do anything here.
      if (e.target === this.get('element').querySelector(handleSelector)) {
        return;
      }

      this._jumpScroll(e);
    },

    // private methods

    /**
     * Convert the mouse position into a percentage of the scrollbar height/width.
     * and sends to parent
     *
     * @param eventOffset
     * @param dragOffset
     * @private
     */
    _drag(eventOffset, dragOffset) {
      var scrollbarOffset = this._scrollbarOffset();

      var dragPos = eventOffset - scrollbarOffset - dragOffset; // Convert the mouse position into a percentage of the scrollbar height/width.

      var dragPerc = dragPos / this._scrollbarSize();

      this.get('onDrag')(dragPerc);
    },

    /**
     * Calls `onJumpTo` action with a boolean indicating the direction of the jump, and the jQuery MouseDown event.
     *
     * If towardsAnchor is true, the jump is in a direction towards from the initial anchored position of the scrollbar.
     *  i.e. for a vertical scrollbar, towardsAnchor=true indicates moving upwards, and towardsAnchor=false is downwards
     *       for a horizontal scrollbar, towardsAnchor=true indicates moving left, and towardsAnchor=false is right
     *
     * @param e
     * @private
     */
    _jumpScroll(e) {
      var eventOffset = this._jumpScrollEventOffset(e);

      var handleOffset = this._handlePositionOffset();

      var towardsAnchor = eventOffset < handleOffset;
      this.get('onJumpTo')(towardsAnchor, e);
    },

    _startDrag(e) {
      return this._eventOffset(e) - this._handleOffset();
    },

    _handleOffset() {
      return this.get('element').querySelector(handleSelector).getBoundingClientRect()[this.get('offsetAttr')];
    },

    _handlePositionOffset() {
      var el = this.get('element').querySelector(handleSelector);
      var position = {
        left: el.offsetLeft,
        top: el.offsetTop
      };
      return position[this.get('offsetAttr')];
    },

    _scrollbarOffset() {
      return this.get('element').getBoundingClientRect()[this.get('offsetAttr')];
    },

    /**
     * Returns the offset from the anchor point derived from this MouseEvent
     * @param e MouseEvent
     * @return {Number}
     */
    _jumpScrollEventOffset(e) {
      return e[this.get('jumpScrollOffsetAttr')];
    },

    _eventOffset(e) {
      return e[this.get('eventOffsetAttr')];
    },

    _scrollbarSize() {
      return this.get('element')["offset".concat((0, _string.capitalize)(this.get('sizeAttr')))];
    },

    actions: {
      startDrag() {
        this.startDrag.apply(this, arguments);
      }

    }
  });

  _exports.default = _default;
});